<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
        <h2 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Kalender
        </h2>
      </column>
      <column
        xs="12"
        md="8">
        <iframe
          id="iFrameResizer_kalender"
          src="https://fiskeribladet.no/intrafish/calendar/intrafish_iframe.asp"
          frameborder="0"
          seamless
          scrolling="no"
          style="width: 100%;min-width: 100%;"
          onload="window.parent.scrollTo(0,0)"></iframe>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'kalender-page',
  extends: Page,
  embed(head, body) {
    head.add(
      `<script
        type="text/javascript"
        src="https://fiskeribladet.no/intrafish/calendar/iframeResizer.min.js">
        <` + `/script>`
    );
    body.add(
      `<script type="text/javascript">
        iFrameResize({log:true}, '#iFrameResizer_kalender');
        <` + `/script>`
    );
  }
};
</script>
